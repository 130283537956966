export function hasAccess(user) {
  return user?.roles.some((r) => {
    return r.role === 'viewer' || r.role === 'admin' || r.role === 'superAdmin';
  });
}

export function isAdmin(user) {
  return user?.roles.some((r) => {
    return r.role === 'admin' || r.role === 'superAdmin';
  });
}

export function hasRoles(user, roles) {
  return user?.roles.some((r) => {
    return roles.includes(r.role);
  });
}

export function canAccessExperimentalFeature(user) {
  if (!user) return false;
  if (user.isExperimentalTester) return true;
  const isRekallAdmin =
    user.email.match(/rekall/) || user.email.match(/admin@vetspire.com/);
  return isRekallAdmin;
}

export function canExportCsv(user) {
  if (!user) return false;
  
  // Check if the user is a superadmin
  const isSuperAdmin = user.roles.some(r => r.role === 'superAdmin');
  
  // User must be both a superadmin and have the experimental tester flag
  return isSuperAdmin && user.isExperimentalTester;
}